import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Animation from '../images/ani-graph.inline.svg';

const PrivacyPage = () => {
    return (
        <Layout>
            <Seo
                title="Privacy Policy - Lifespan Consultants"
                description="Life Insurance and Family Planning"
            />
            <div className="app-title">
                <div className="title-txt">
                    <h1
                        data-sal="slide-up"
                        data-sal-duration="500"
                        data-sal-delay="400"
                        data-sal-easing="easeOutCubic"
                    >
                        Privacy Policy
                    </h1>
                </div>
                <div className="app-ani">
                    <Animation />
                </div>
            </div>
            <div className="content">
                <div className="container">
                    <div className="grid">
                        <div className="col-lg-12">
                            <div className="privacyPolicy">
                                <p>
                                    GBA Kotkis Pty Ltd ATF Lifespan Consultants
                                    WA Unit Trust (“Lifespan” “we” or “us”),
                                    recognises the importance of your privacy.
                                    We understand that your personal
                                    information, and how we use it, is very
                                    important to you. The following statement
                                    reflects our policy in relation to the
                                    personal information that we collect, and
                                    follows the guidelines set by the Australian
                                    Privacy Principles under the Commonwealth
                                    Privacy Act (“APPs”).
                                </p>
                                <h2>Collecting Personal Information</h2>
                                <p>
                                    We only collect personal information
                                    relevant to the functions that we perform
                                    (“information”) and the information
                                    collected is dependent on our relationship
                                    with you.
                                </p>
                                <p>
                                    This information may include the following,
                                    although this is not an exhaustive list:
                                </p>
                                <ul>
                                    <li>
                                        information from you that may include
                                        your identity and contact details,
                                        financial information and other personal
                                        information;
                                    </li>
                                    <li>
                                        information from you about others, such
                                        as services providers, agents, advisers
                                        and employers; and
                                    </li>
                                    <li>
                                        if required, we will act as your agent
                                        to obtain your financial information
                                        from a third party on your behalf.
                                    </li>
                                </ul>
                                <p>
                                    We will collect the information from you or
                                    from someone authorised to act on your
                                    behalf. Should you give us personal
                                    information about other individuals (such as
                                    your referees) we rely on you to obtain
                                    their prior consent.
                                </p>
                                <p>
                                    We also collect information from you when
                                    you use our website www.lifespanwa.com.au.
                                    Your use of the facilities and services
                                    available through our website will determine
                                    the amount and type of information that we
                                    collect about you. Some of this information
                                    will not be personal because it will not
                                    reveal your identity. The only personal
                                    information which we collect about you when
                                    you use our website is that you tell us
                                    about yourself, for example, by completing
                                    an online form, where you subscribe to a
                                    newsletter, or information you provide to us
                                    when you send us an email. We will record
                                    your email address if you send us an email.
                                </p>
                                <p>
                                    We also collect personal information in
                                    person, in writing, by telephone, and
                                    through other methods of communication. Most
                                    of the personal information that we collect
                                    is provided to us by our clients, when they
                                    initially instruct us, and during the course
                                    of us providing services to them.
                                </p>
                                <p>
                                    From time to time we may use the information
                                    to provide you with news or offers about
                                    services that may be of interest to you. If,
                                    at any time, you no longer wish to receive
                                    this material, please notify us and we will
                                    remove your details from our database of
                                    contacts. By submitting identifying data, a
                                    visitor to our website is agreeing to
                                    Lifespan’s use of such data in the manner
                                    described in this Privacy Policy.
                                </p>
                                <p>
                                    We may use and disclose your personal
                                    information to:
                                </p>
                                <ul>
                                    <li>provide requested services;</li>
                                    <li>manage our relationship with you;</li>
                                    <li>
                                        comply with our legal obligations and
                                        assist government and law enforcement
                                        agencies or regulators; and
                                    </li>
                                    <li>
                                        identify and inform you of other
                                        products or services that we think may
                                        be of interest to you.
                                    </li>
                                </ul>
                                <h2>
                                    Who will see or have access to your personal
                                    information?
                                </h2>
                                <p>
                                    We may exchange your personal information
                                    with third parties where this is permitted
                                    by law, some of which may be located
                                    overseas. Third parties include:
                                </p>
                                <ul>
                                    <li>
                                        service providers, for example banks and
                                        other financial institutions;
                                    </li>
                                    <li>
                                        those to whom we outsource certain
                                        functions, for example contractors and
                                        information technology support;
                                    </li>
                                    <li>
                                        financial consultants, accountants,
                                        lawyers and advisers acting on your
                                        behalf;
                                    </li>
                                    <li>auditors;</li>
                                    <li>
                                        your referees, such as your employer or
                                        former employers, to verify information
                                        you have provided; and
                                    </li>
                                    <li>
                                        government and law enforcement agencies
                                        or regulators.
                                    </li>
                                </ul>
                                <p>
                                    In each case, we will treat information we
                                    collect in accordance with the principles
                                    set out above.
                                </p>
                                <h2>Security of your information</h2>
                                <p>
                                    We place great importance on the security of
                                    the information we hold about you. We are
                                    committed to preventing unauthorised access
                                    to, or disclosure, misuse or loss of, that
                                    information. We will not use or disclose
                                    information about an individual other than
                                    for its primary purpose of collection,
                                    unless:
                                </p>
                                <ul>
                                    <li>
                                        the individual has consented to such use
                                        or disclosure; or
                                    </li>
                                    <li>
                                        we have reason to suspect that unlawful
                                        activity has been, or may be, engaged
                                        in, and use or disclose the information
                                        as a necessary part of its investigation
                                        of the matter or in reporting its
                                        concerns to relevant persons or
                                        authorities; or
                                    </li>
                                    <li>
                                        the use or disclosure is required or
                                        authorised by or under law; or
                                    </li>
                                    <li>
                                        we reasonably believe that the use or
                                        disclosure is reasonably necessary for
                                        or required by a specified purpose by or
                                        on behalf of an enforcement body.
                                    </li>
                                </ul>
                                <p>
                                    Maintaining confidentiality and security of
                                    information is a core standard of our
                                    operations. Your information is stored in
                                    secure facilities and our information
                                    systems and files are kept secure from
                                    unauthorised access.
                                </p>
                                <h2>
                                    Access and Accuracy of your Personal
                                    Information
                                </h2>
                                <p>
                                    All information you provide will be held by
                                    Lifespan. You can ask for access to your
                                    information by contacting us. You can also
                                    contact us for more detailed information on
                                    how we collect, handle and secure your
                                    personal information.
                                </p>
                                <h2>
                                    What to do if you believe the information we
                                    hold about your is inaccurate
                                </h2>
                                <p>
                                    If you believe that any information that we
                                    hold about you is inaccurate or out of date,
                                    please contact us and we will review and
                                    update the relevant information.
                                </p>
                                <h2>Direct Marketing</h2>
                                <p>
                                    In order to provide services to you we may
                                    use your information in order to update you
                                    as to the services being offered by
                                    Lifespan. If you do not wish to receive
                                    marketing information, you may at any time
                                    decline to receive such information by
                                    contacting us.
                                </p>
                                <h2>Privacy Complaints and Queries</h2>
                                <p>
                                    If you have complaints, concerns or queries
                                    regarding our Privacy Policy, please
                                    contact:
                                </p>
                                <p>
                                    Privacy Officer <br />
                                    Lifespan Consultants WA
                                    <br />
									660C Newcastle St, 
                                    <br />
                                    Leederville WA 6007<br />
                                    Phone: (08) 6323 8691
                                    <br />
                                    Email: gavin@lifespanwa.com.au
                                </p>
                                <p>
                                    For more information about the privacy
                                    legislation or the APPs we recommend that
                                    you visit the Office of the Australian
                                    Information Commissioner’s website at
                                    www.oaic.gov.au.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default PrivacyPage;